import { Box } from '@mui/system';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useSocket } from 'app/components/Context/SocketContext';
import PageHeader from 'app/design/speedBot/PageHeader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetLogData } from 'app/hooks/useGetLogData';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useGetBotLogs from 'services/Bots/useGetBotLogs';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { LazyLog } from 'react-lazylog';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import useGetBotLogFiles from 'services/Bots/useGetBotLogsFile';

type Props = {
  botId: string;
  type: string;
  str_id?: string;
  from?: string;
};

const CodeStrategyLogsContent = ({ botId, type, str_id, from }: Props) => {
  const { data: fileLogs, refetch } = useGetBotLogs(Number(str_id), type);
  // const {data: debugLogs, refetch}
  const { updatedLogs, updatedErrors, resetLogMessages } = useGetLogData(
    Number(botId),
  );
  const [selectedFile, setSelectedFile] = useState<string>('');
  const { data: fileList } = useGetBotLogFiles(Number(botId), selectedFile);
  const { socket } = useSocket();
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const history = useHistory();

  const onSelectedFilesLog = (event: SelectChangeEvent) => {
    const urlParams = new URLSearchParams();
    urlParams.set('filename', event.target.value);
    history.replace({
      search: urlParams.toString(),
    });
    setSelectedFile(event.target.value);
  };

  useEffect(
    () => {
      socket?.emit('bot_subscription', {
        bot_id: Number(botId),
        type: 'subscribe',
      });
      return () => {
        socket?.emit('bot_subscription', {
          bot_id: Number(botId),
          type: 'unsubscribe',
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (updatedErrors) {
      updatedErrors.map(item => {
        enqueueSnackbar(item, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
        });
      });
    }
  }, [updatedErrors]);

  const logText = fileLogs?.data
    ? fileLogs.data.concat(updatedLogs.join('\n'))
    : updatedLogs.join('\n');

  return (
    <SnackbarProvider>
      <Box>
        {from && from === 'editorPage' ? (
          <PageHeader
            variant="inactive"
            buttonText={'Bot-builder'}
            title={t('user_bot_startegy.logs')}
            buttonHandleClick={() => history.goBack()}
            breadcrumb={
              <Breadcrumbs page={PAGES.BOT_STRATEGY_BUILDER} data={{}} />
            }
          />
        ) : (
          <> </>
        )}
        {fileList && fileList.files.length > 0 && (
          <Box display={'flex'} justifyContent={'right'} mr={3} mb={2}>
            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel>Select File</InputLabel>
              <Select
                label="Select File"
                onChange={event => {
                  onSelectedFilesLog(event);
                }}
                placeholder="SelectFile"
                value={selectedFile}
                style={{
                  fontSize: '16px',
                }}
                autoWidth
              >
                {fileList &&
                  fileList.files.map(value => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {(Number(botId) || Number(str_id)) && (
          <Box className="m-main-spacing" flex={1}>
            {/* <Box
              sx={{
                height: '70vh',
                backgroundColor: 'black',
                overflowY: 'scroll',
              }}
            >
              <Typography sx={{ color: 'white', whiteSpace: 'pre' }}>
                {LogsData?.data}
              </Typography>
              {updatedLogs ? (
                <Typography sx={{ color: 'white', whiteSpace: 'pre' }}>
                  {updatedLogs.map(item => (
                    <Typography key={item}>{item}</Typography>
                  ))}
                </Typography>
              ) : (
                <></>
              )}
            </Box> */}
            <LazyLog
              enableSearch
              caseInsensitive
              selectableLines
              text={logText || ' '}
              height={Math.round(window.innerHeight / (100 / 70))}
              style={{
                fontSize: '16px',
              }}
            />

            <Box display={'flex'} justifyContent={'center'} mt={2}>
              <MuiButton
                variant="contained"
                color="secondary"
                fullWidth={!isMdUp}
                onClick={() => {
                  void refetch().then(resetLogMessages);
                }}
                sx={{
                  mr: { xs: 0, md: 2 },
                }}
              >
                Refresh Logs
              </MuiButton>
            </Box>
          </Box>
        )}
      </Box>
    </SnackbarProvider>
  );
};

export default memo(CodeStrategyLogsContent);
