import { Box, Typography } from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { ReactNode } from 'react';

const CarouselItem = ({
  children,
  title,
  description,
}: {
  children: ReactNode;
  title: string;
  description: string;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <Box
      key={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: isMdUp ? 'calc(100vh - 277px)' : 'calc(100vh - 189px)',
        justifyContent: 'flex',
        alignItems: 'center',
        // marginTop: '%',
        padding: '20px',
        // backgroundColor: '#141e30',
      }}
      className="carousel-slide"
    >
      {/* Centered SVG */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <img src=''/> */}
        {children}
      </Box>

      {/* Typography at the bottom */}
      <Box sx={{ textAlign: 'center', mb: 1 }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '1rem',

            color: 'text.secondary',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default CarouselItem;
