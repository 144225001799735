import { useQuery } from 'react-query';
import { GetPlans } from 'services/apiServices';
import { Errors } from 'types/ApiServicesTypes';
import {
  GetPlansTransformResult,
  SubscriptionPlan,
  SubscriptionTransformPlan,
} from 'types/SubscriptionPlans';
import { chain } from 'lodash';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const transformGetPlansResponse = (
  plans: SubscriptionPlan[],
): GetPlansTransformResult => {
  plans = plans.filter(x => x.recurring_price > 0);
  plans.sort(function (a, b) {
    return a.recurring_price - b.recurring_price;
  });
  let transformPlans: GetPlansTransformResult = [];

  const grpPlans = chain(plans)
    .groupBy(x => x.plan_name)
    .map((members, key) => ({ key, members }))
    .value();

  transformPlans = grpPlans.map((grp, i) => {
    if (grp.members.length === 2) {
      const data: SubscriptionTransformPlan = {
        id: i + 1,
        plan_id_monthly: grp.members[0].plan_id,
        plan_id_yearly: grp.members[1].plan_id,
        plan_name: grp.members[0].plan_name,
        interval: grp.members[0].interval,
        coupon_data: grp.members[0].coupon_data || null,
        number_of_period_monthly: grp.members[0].number_of_period,
        number_of_period_yearly: grp.members[1].number_of_period,
        duration_monthly: grp.members[0].duration,
        duration_yearly: grp.members[1].duration,
        recurring_price_monthly: grp.members[0].recurring_price,
        recurring_price_yearly: grp.members[1].recurring_price,
        description_monthly: grp.members[0].description,
        description_yearly: grp.members[1].description,
        capabilities: grp.members[0].capabilities,
        template_type: grp.members[0].template_type,
      };
      return data;
    } else {
      if (grp.members[0].period === 'Months') {
        const data: SubscriptionTransformPlan = {
          id: i + 1,
          plan_id_monthly: grp.members[0].plan_id,
          plan_id_yearly: grp.members[1] ? grp.members[1].plan_id : 0,
          plan_name: grp.members[0].plan_name,
          interval: grp.members[0].interval,
          coupon_data: grp.members[0].coupon_data || null,
          number_of_period_monthly: grp.members[0].number_of_period,
          number_of_period_yearly: grp.members[1]
            ? grp.members[1].number_of_period
            : '',
          duration_monthly: grp.members[0].duration,
          duration_yearly: grp.members[1] ? grp.members[1].duration : '',
          recurring_price_monthly: grp.members[0].recurring_price,
          recurring_price_yearly: grp.members[1]
            ? grp.members[1].recurring_price
            : 0,
          description_monthly: grp.members[0].description,
          description_yearly: grp.members[1] ? grp.members[1].description : '',
          capabilities: grp.members[0].capabilities,
          template_type: grp.members[0].template_type,
        };
        return data;
      } else {
        const data: SubscriptionTransformPlan = {
          id: i + 1,
          plan_id_monthly: grp.members[1] ? grp.members[1].plan_id : 0,
          plan_id_yearly: grp.members[0] ? grp.members[0].plan_id : 0,
          plan_name: grp.members[0].plan_name,
          interval: grp.members[0].interval,
          coupon_data: grp.members[0].coupon_data || null,
          number_of_period_monthly: grp.members[1]
            ? grp.members[1].number_of_period
            : '',
          number_of_period_yearly: grp.members[0]
            ? grp.members[0].number_of_period
            : '',
          duration_monthly: grp.members[1] ? grp.members[1].duration : '',
          duration_yearly: grp.members[0] ? grp.members[0].duration : '',
          recurring_price_monthly: grp.members[1]
            ? grp.members[1].recurring_price
            : 0,
          recurring_price_yearly: grp.members[0]
            ? grp.members[0].recurring_price
            : 0,
          description_monthly: grp.members[1] ? grp.members[1].description : '',
          description_yearly: grp.members[0] ? grp.members[0].description : '',
          capabilities: grp.members[0].capabilities,
          template_type: grp.members[0].template_type,
        };
        return data;
      }
    }
  });
  return transformPlans;
};

export default function useGetPlans(couponCode = '') {
  const queryKey = ['GetPlans', couponCode];
  const isShowBacktest = useIsFeatureFlag([FEATURES.IS_SHOW_BACKTEST]);

  const objQuery = useQuery<GetPlansTransformResult, Errors>(queryKey, () =>
    GetPlans(couponCode).then(res => {
      const modifiedRes = isShowBacktest
        ? res
        : res.map(item => ({
            ...item, // Spread all properties of the SubscriptionTransformPlan
            capabilities: item.capabilities.filter(
              capability => !capability.name.toLowerCase().includes('backtest'),
            ),
          }));
      // res.map(item=>item.capabilities.)
      return transformGetPlansResponse(modifiedRes);
    }),
  );

  return objQuery;
}
