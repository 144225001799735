import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, BottomNavigationAction, Avatar } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import NotificationButton from '../../Portfolio/NotificationButton';
import MuiBottomNavigation from 'app/design/uiComponents/MuiBottomNavigation';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { isUserLoggedIn } from 'utils/auth';
import { useContext, useEffect, useState } from 'react';
import SignInModal from 'app/components/Auth/SignInModal';
import {
  getFirstSecondLetterfromName,
  getQueryParam,
} from 'utils/GenericFunctions';
import {
  AccountCircle,
  AccountCircleOutlined,
  Brightness4,
  Brightness7,
} from '@mui/icons-material';
import { GetProfileResult } from 'types/ApiServicesTypes';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
function AppbarNotification({
  activeNav,
  profile,
}: {
  activeNav: string;
  profile?: GetProfileResult;
}) {
  const theme = useTheme();
  const history = useHistory();
  const isMdUp = useGetMediaQueryUp('md');
  const isLoggedIn = isUserLoggedIn();
  const [isOpenLoginModal, setOpenLoginModal] = useState(false);
  const { isDarkTheme, toggleTheme } = useContext(themeContext);
  const isSideBarView = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);
  const isDarkThemeFeature = useIsFeatureFlag([FEATURES.IS_DARK_THEME_FEATURE]);
  useEffect(() => {
    const modalopen = getQueryParam('modal');

    if (modalopen === 'india' || modalopen === 'usa') {
      setOpenLoginModal(true);
    }
  }, []);
  const isLgUp = useGetMediaQueryUp('max');
  return (
    <>
      {isMdUp && (
        <Box display="flex" alignItems="center">
          {isLoggedIn ? (
            <>
              <MuiBottomNavigation
                // showLabels
                onChange={() => {
                  toggleTheme();
                }}
                sx={{
                  height: theme => theme.spacing(8),
                  backgroundColor: theme => theme.palette.common.white,
                  [theme.breakpoints.up('md')]: {
                    flex: 1,
                    mx: 1,
                  },
                }}
              >
                {isLgUp && !isSideBarView && isDarkThemeFeature && (
                  <BottomNavigationAction
                    label=""
                    icon={
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {/* DeskTop View */}
                        <Box
                          sx={{
                            // backgroundColor: theme.palette.common.black,
                            // color: theme.palette.common.white,
                            width: 30,
                            height: 30,
                            fontSize: 14,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSmooth: 'auto',
                            pb: '10px',
                          }}
                        >
                          {isDarkTheme ? <Brightness4 /> : <Brightness7 />}
                        </Box>
                      </Box>
                    }
                    sx={{ mt: '-2px' }}
                    // onClick={toggleTheme}
                  />
                )}
              </MuiBottomNavigation>
              <MuiBottomNavigation
                showLabels
                value={activeNav === 'profile' ? 'menu' : activeNav}
                onChange={(event, newValue) => {
                  history.push(`/${newValue}`);
                }}
                sx={{
                  height: theme => theme.spacing(8),
                  backgroundColor: theme => theme.palette.common.white,
                  [theme.breakpoints.up('md')]: {
                    flex: 1,
                    mx: 1,
                  },
                }}
              >
                <BottomNavigationAction
                  // label="Menu"
                  icon={
                    profile && profile.name.trim().length > 0 ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {/* DeskTop View */}
                        <Avatar
                          sx={{
                            backgroundColor: theme.palette.common.black,
                            color: theme.palette.common.white,
                            width: 30,
                            height: 30,
                            fontSize: 14,
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                            border: '1px solid black',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSmooth: 'auto',
                          }}
                        >
                          {getFirstSecondLetterfromName(profile?.name)}
                        </Avatar>
                      </Box>
                    ) : activeNav === 'menu' ? (
                      <AccountCircle />
                    ) : (
                      <AccountCircleOutlined />
                    )
                  }
                  value="menu"
                  sx={{ mt: '-2px' }}
                />
              </MuiBottomNavigation>
              <NotificationButton />
            </>
          ) : (
            <MuiBottomNavigation
              showLabels
              onChange={() => {
                // history.push(`/${newValue}`);
                // open login modal
                // showAlert!('Please login to the app to continue', 'warning');
                setOpenLoginModal(true);
              }}
              sx={{
                height: theme => theme.spacing(8),
                backgroundColor: theme => theme.palette.common.white,
                [theme.breakpoints.up('md')]: {
                  flex: 1,
                  mx: 1,
                },
              }}
            >
              <BottomNavigationAction
                label="Login"
                icon={<LoginIcon />}
                sx={{ mt: '-2px' }}
              />
            </MuiBottomNavigation>
          )}
        </Box>
      )}
      <SignInModal
        open={isOpenLoginModal}
        handleClose={() => setOpenLoginModal(false)}
      />
    </>
  );
}

export default AppbarNotification;
