import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface PureIframeProps {
  url: string;
  maxRetries?: number;
}

const PureIframe: React.FC<PureIframeProps> = ({ url, maxRetries = 5 }) => {
  const [loadingState, setLoadingState] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [error, setError] = useState(false);

  // Generate a unique URL to avoid caching issues during retries
  const uniqueUrl = `${url}/?timestamp=${new Date().getTime()}&retry=${retryCount}`;

  const handleIframeLoad = (event: React.SyntheticEvent<HTMLIFrameElement>) => {
    const iframeDocument =
      event.currentTarget.contentDocument ||
      event.currentTarget.contentWindow?.document;

    // Check if iframe contains a 502 error
    if (
      iframeDocument &&
      (iframeDocument.body.textContent as string).includes('502 Bad Gateway')
    ) {
      setError(true);
      setLoadingState(true); // Keep showing the loading state during retries
    } else {
      setError(false);
      setLoadingState(false); // Hide loading state when successfully loaded
    }
  };

  useEffect(() => {
    let retryInterval: NodeJS.Timeout | undefined;

    if (error && retryCount < maxRetries) {
      retryInterval = setTimeout(() => {
        setRetryCount(prev => prev + 1); // Increment retry count
      }, 3000); // Retry every 3 seconds
    }

    return () => {
      if (retryInterval) clearTimeout(retryInterval);
    };
  }, [error, retryCount, maxRetries]);

  return (
    <div style={{ height: '80vh', position: 'relative' }}>
      {loadingState && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            {error
              ? 'Retrying to load the environment...'
              : 'Your coding environment is being prepared'}
          </Typography>
          {error && (
            <Typography variant="caption" sx={{ marginTop: 1 }}>
              Retrying... Attempt {retryCount}/{maxRetries}
            </Typography>
          )}
          {!error && (
            <Typography variant="caption" sx={{ marginTop: 1 }}>
              This might take a while
            </Typography>
          )}
        </Box>
      )}
      <iframe
        loading="eager"
        key={uniqueUrl}
        src={uniqueUrl}
        width="100%"
        height="90%"
        onLoad={handleIframeLoad}
        style={{ display: loadingState ? 'none' : 'block' }} // Hide iframe while loading
      ></iframe>
      {retryCount >= maxRetries && error && (
        <Box
          sx={{
            textAlign: 'center',
            marginTop: 2,
            color: 'red',
          }}
        >
          <Typography variant="body1">
            Failed to load the environment after multiple attempts.
          </Typography>
          <Typography variant="caption">
            Please try refreshing the page.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default PureIframe;
