import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import CopyJsonMessage from 'app/components/Builder/OptionsStrategy/ExternalSignalSteps/CopyJsonMessage';
import CopyWebHook from 'app/components/Builder/OptionsStrategy/ExternalSignalSteps/CopyWebhook';
import CreateTradingViewAlert from 'app/components/Builder/OptionsStrategy/ExternalSignalSteps/CreateTradingViewAlert';
import MaximumEntriesInput from 'app/components/Builder/OptionsStrategy/ExternalSignalSteps/MaximumEntriesInput';
import { OptionSetParam } from 'app/components/Builder/OptionsStrategy/types';
import PageHeader from 'app/design/speedBot/PageHeader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiModal from 'app/design/uiComponents/MuiModal';
import {
  useGetMediaQueryDown,
  useGetMediaQueryUp,
} from 'app/hooks/useGetMediaQuery';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetWebHookAuthKey from 'services/Bots/useGetWebHookAuthKey';
import CloseIcon from '@mui/icons-material/Close';
import { getFullDomain } from 'utils/GenericFunctions';
import * as Yup from 'yup';
import ShowMoreText from 'react-show-more-text';
import StrategyLogo from 'assets/images/signalguide/tradingview_strategy.png';
import IndicatorLogo from 'assets/images/signalguide/tradingview_indicator.png';
import { themeContext } from 'app/components/Context/ToggleThemeContext';

interface ExternalSignalFormDataType {
  enable: boolean;
  source: string;
  max_signals_entry: number;
  max_signals_exit: number;
  webhook: string;
}

interface ExternalSignalProps {
  open: boolean;
  handleClose: () => void;
  saveData: (data: ExternalSignalFormDataType) => void;
  initialData: OptionSetParam;
  isReadonly: boolean;
  bId: number;
}

const curDomain = getFullDomain();
const ExternalSignalModal = ({
  open,
  handleClose,
  saveData,
  initialData,
  isReadonly,
  bId,
}: ExternalSignalProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <MuiModal
      open={open}
      handleClose={handleClose}
      isFullScreen={!isMdUp}
      maxWidth="lg"
      body={
        // <Box className="p-main-spacing" >
        <ExternalSignalWrapper
          initialData={initialData}
          saveData={saveData}
          handleClose={handleClose}
          isReadonly={isReadonly}
          bId={bId}
        />
        // </Box>
      }
    />
  );
};

export default ExternalSignalModal;

export const ExternalSignalWrapper = ({
  initialData,
  saveData,
  handleClose,
  isReadonly,
  bId,
}: {
  initialData: OptionSetParam;
  saveData: (data: ExternalSignalFormDataType) => void;
  handleClose: (success: boolean) => void;
  isReadonly: boolean;
  bId: number;
}) => {
  const { t } = useTranslation();
  const { data: data } = useGetWebHookAuthKey();
  const webhook = `${curDomain}/api/external_signal?key=${data?.key}`;
  const [step, setStep] = useState(0);
  const [viewType, setViewType] = useState('home');
  const [type, setType] = useState('');
  const isMdUp = useGetMediaQueryUp('md');
  const [isAddExitSignal, setIsAddExitSignal] = useState(
    (initialData.external_signal['max_signals_exit'] as number) > 0
      ? true
      : false,
  );
  const { values, handleSubmit, setFieldValue, errors } = useFormik({
    initialValues: {
      enable: (initialData.external_signal['enable'] as boolean) || false,
      source:
        (initialData.external_signal['source'] as string) || 'trading_view',
      max_signals_entry:
        (initialData.external_signal['max_signals_entry'] as number) || 1,
      max_signals_exit:
        (initialData.external_signal['max_signals_exit'] as number) || 0,
      webhook: webhook,
    },
    validationSchema: Yup.object().shape({
      max_signals_entry: Yup.number().min(
        1,
        'Entry Signal must be greater than or equal to 1',
      ),
      max_signals_exit: Yup.number().min(
        isAddExitSignal ? 1 : 0,
        'Exit Signal must be greater than or equal to 1',
      ),
    }),
    onSubmit: values => {
      values.enable = true;
      saveData(values);
      handleClose(true);
    },
  });
  const handleBridgeCancel = () => {
    values.enable = false;
    saveData(values);
    handleClose(true);
  };

  const EntrySignalStepRendering = [
    {
      step: 0,
      title: t('user_bot_startegy.option_builder.external_signal.step1_title', {
        signal_type: 'Entry',
      }),
      renderer: CreateTradingViewAlert,
      param: {
        img_url: data?.create_alert_img,
      },
    },
    {
      step: 1,
      title: t('user_bot_startegy.option_builder.external_signal.step2_title', {
        signal_type: 'Entry',
      }),
      renderer: CopyJsonMessage,
      param: {
        signalType: 'Entry',
        bId: bId,
        setName: initialData.name as string,
        indicator_img_url: data?.copy_jsondata_img,
        tradingview_type: type,
        strategy_img_url: data?.copy_strategy_jsondata_img,
      },
    },
    {
      step: 2,
      title: t('user_bot_startegy.option_builder.external_signal.step3_title', {
        signal_type: 'Entry',
      }),
      renderer: CopyWebHook,
      param: {
        signalType: 'Entry',
        url: webhook,
        img_url: data?.copy_webhook_img,
      },
    },
    {
      step: 3,
      title: t('user_bot_startegy.option_builder.external_signal.step4_title', {
        signal_type: 'Entry',
      }),
      renderer: MaximumEntriesInput,
      param: {
        maxEntries: values.max_signals_entry,
        setMaxEntries: (maxEntries: number) =>
          setFieldValue('max_signals_entry', maxEntries),
        errors: errors.max_signals_entry,
        type: 'Entry',
      },
    },
  ];

  const handleClick = (type: string) => {
    setViewType(type);
    setType(type);
  };
  const EntryLength = EntrySignalStepRendering.length;

  const ExitSignalStepRendering = [
    {
      step: 4,
      title: t('user_bot_startegy.option_builder.external_signal.step1_title', {
        signal_type: 'Exit',
      }),
      renderer: CreateTradingViewAlert,
      param: {
        img_url: data?.create_alert_img,
      },
    },
    {
      step: 5,
      title: t('user_bot_startegy.option_builder.external_signal.step2_title', {
        signal_type: 'Exit',
      }),
      renderer: CopyJsonMessage,
      param: {
        signalType: 'Exit',
        bId: bId,
        setName: initialData.name as string,
        indicator_img_url: data?.copy_jsondata_img,
        tradingview_type: type,
        strategy_img_url: data?.copy_strategy_jsondata_img,
      },
    },
    {
      step: 6,
      title: t('user_bot_startegy.option_builder.external_signal.step3_title', {
        signal_type: 'Exit',
      }),
      renderer: CopyWebHook,
      param: {
        signalType: 'Exit',
        url: webhook,
        img_url: data?.copy_webhook_img,
      },
    },
    {
      step: 7,
      title: t('user_bot_startegy.option_builder.external_signal.step4_title', {
        signal_type: 'Exit',
      }),
      renderer: MaximumEntriesInput,
      param: {
        maxEntries: values.max_signals_entry,
        setMaxEntries: (maxEntries: number) =>
          setFieldValue('max_signals_exit', maxEntries),
        errors: errors.max_signals_exit,
        type: 'Exit',
      },
    },
  ];
  const ExitLength = ExitSignalStepRendering.length;
  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      {viewType === 'home' ? (
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <Box
            display={{
              sm: 'block',
              md: 'flex',
            }}
            mb={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <PageHeader
              variant={`${!isMdUp ? 'withIcon' : 'default'}`}
              title={
                <Typography
                  variant="h6"
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'pre-line',
                  }}
                >
                  Tradingview External Signal Configuration
                </Typography>
              }
              iconProps={{
                handleClick: handleClose,
                icon: <CloseIcon fontSize="small" />,
              }}
            />
            {values.enable && (
              <Box className={`${!isMdUp && 'm-main-spacing'}`}>
                <MuiButton
                  onClick={handleBridgeCancel}
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: { sm: 2, md: 0 },
                    mr: { sm: 0, md: 2 },
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  Remove TradingView
                </MuiButton>
              </Box>
            )}
          </Box>
          <HomeExternalSignal handleClick={handleClick} type={type} />
          <Box
            className="m-main-spacing"
            sx={{
              textAlign: 'center',
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'center',
              flexDirection: 'row-reverse',
              gap: 2,
              pb: 2,
            }}
          >
            <MuiButton
              onClick={handleClose}
              variant="contained"
              color="secondary"
              sx={{
                mb: 2,
              }}
            >
              {t(
                'user_bot_startegy.option_builder.external_signal.buttons.cancel',
              )}
            </MuiButton>
          </Box>
        </Box>
      ) : (
        <>
          <Box flex={1} flexGrow={1}>
            <Box
              display={{
                sm: 'block',
                md: 'flex',
              }}
              mb={2}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <PageHeader
                variant={`${!isMdUp ? 'withIcon' : 'default'}`}
                title={
                  <Typography
                    variant="h6"
                    style={{
                      display: 'inline-block',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {t(
                      'user_bot_startegy.option_builder.external_signal.title',
                      {
                        signal_type: `${step < EntryLength ? 'Entry' : 'Exit'}`,
                      },
                    )}
                  </Typography>
                }
                iconProps={{
                  handleClick: handleClose,
                  icon: <CloseIcon fontSize="small" />,
                }}
              />
              {values.enable && (
                <Box className={`${!isMdUp && 'm-main-spacing'}`}>
                  <MuiButton
                    onClick={handleBridgeCancel}
                    variant="contained"
                    color="primary"
                    sx={{
                      mb: { sm: 2, md: 0 },
                      mr: { sm: 0, md: 2 },
                      width: { xs: '100%', md: 'auto' },
                    }}
                  >
                    Remove TradingView
                  </MuiButton>
                </Box>
              )}
            </Box>
            {step < EntryLength && (
              <Box className="m-main-space" my={2}>
                <Stepper activeStep={step} alternativeLabel>
                  {EntrySignalStepRendering.map((stepTitle, index) => (
                    <Step key={index}>
                      <StepLabel sx={{ fontSize: '10px' }}>
                        {isMdUp && stepTitle.title}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}
            {step > EntryLength - 1 && (
              <Box className="m-main-space" my={2}>
                <Stepper activeStep={step - EntryLength} alternativeLabel>
                  {ExitSignalStepRendering.map((stepTitle, index) => (
                    <Step key={index}>
                      <StepLabel>{isMdUp && stepTitle.title}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}

            {step < EntryLength &&
              EntrySignalStepRendering.map(
                (stepContent, index) =>
                  step === index && (
                    <stepContent.renderer key={index} {...stepContent.param} />
                  ),
              )}
            {step > EntryLength - 1 &&
              ExitSignalStepRendering.map(
                (stepContent, index) =>
                  step === index + EntryLength && (
                    <stepContent.renderer key={index} {...stepContent.param} />
                  ),
              )}
            {step === EntryLength - 1 && (
              <Box mt={1} className="m-main-spacing">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAddExitSignal}
                      onChange={e => {
                        setIsAddExitSignal(!isAddExitSignal);
                        if (e.target.checked) {
                          void setFieldValue(
                            'max_signals_exit',
                            values.max_signals_entry,
                          );
                        } else {
                          void setFieldValue('max_signals_exit', 0);
                        }
                      }}
                      disabled={isReadonly}
                    />
                  }
                  label={
                    <Box display={'flex'}>
                      <Box alignSelf={'center'}>{'Add Exit Signal'}</Box>
                    </Box>
                  }
                  disabled={isReadonly}
                />
                <Alert severity="info" icon={false}>
                  <Typography variant="body2" color="gray" fontWeight={400}>
                    <strong>Note</strong>
                    <Typography>
                      {t(
                        'user_bot_startegy.option_builder.external_signal.note',
                      )}
                    </Typography>
                  </Typography>
                </Alert>
              </Box>
            )}
          </Box>

          {!isReadonly && (
            <Box
              className="m-main-spacing"
              sx={{
                textAlign: 'center',
                display: { xs: 'block', md: 'flex' },
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                gap: 2,
                pb: 2,
              }}
            >
              {step === EntryLength + ExitLength - 1 ||
              (step === EntryLength - 1 && !isAddExitSignal) ? (
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{
                    mb: { sm: 2, md: 0 },
                    width: { xs: '100%', lg: 'auto' },
                  }}
                >
                  {t(
                    'user_bot_startegy.option_builder.external_signal.buttons.save',
                  )}
                </MuiButton>
              ) : (
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (step === EntryLength - 1) {
                      if (errors.max_signals_entry) {
                        setStep(step);
                      } else {
                        setStep(step => step + 1);
                      }
                    } else {
                      setStep(step => step + 1);
                    }
                  }}
                  sx={{
                    mb: { sm: 2, md: 0 },
                    width: { xs: '100%', lg: 'auto' },
                  }}
                >
                  {t(
                    'user_bot_startegy.option_builder.external_signal.buttons.next',
                  )}
                </MuiButton>
              )}
              {/* {step === 0 ? (
                <MuiButton
                  onClick={handleClose}
                  variant="contained"
                  color="secondary"
                  sx={{
                    mb: { sm: 2, md: 0 },
                    width: { xs: '100%', lg: 'auto' },
                  }}
                >
                  {t(
                    'user_bot_startegy.option_builder.external_signal.buttons.cancel',
                  )}
                </MuiButton>
              ) : ( */}
              <MuiButton
                onClick={() => {
                  if (step === 0) setViewType('home');
                  else setStep(step => step - 1);
                }}
                variant="contained"
                color="secondary"
                sx={{
                  mb: { sm: 2, md: 0 },
                  width: { xs: '100%', lg: 'auto' },
                }}
              >
                {t(
                  'user_bot_startegy.option_builder.external_signal.buttons.back',
                )}
              </MuiButton>
              {/* )} */}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const HomeExternalSignal = ({
  handleClick,
  type,
}: {
  handleClick: (type: string) => void;
  type: string;
}) => {
  const isMdDown = useGetMediaQueryDown('md');
  const { isDarkTheme } = useContext(themeContext);
  return (
    <Box
      display={'flex'}
      flex={1}
      className="m-main-spacing"
      flexDirection={'column'}
    >
      <Alert severity="info" icon={false} sx={{ mb: 1 }}>
        <Typography variant="body2" color="black" fontWeight={400}>
          <strong>Note</strong>
          <Typography variant="body2" color={'grey'} textAlign={'justify'}>
            <ul>
              <li>
                <strong>Indicator:</strong>
                {isMdDown ? (
                  <ShowMoreText
                    lines={2}
                    more={'Read More'}
                    less="Less"
                    className="show-more"
                    anchorClass="anchor-show-more"
                    expanded={false}
                    truncatedEndingComponent={'...'}
                  >
                    {' '}
                    <Typography
                      variant="body2"
                      textAlign={'justify'}
                      color={'text.primary'}
                    >{`Choose this if you prefer making trading decisions manually
                      with help from technical analysis. TradingView indicators
                      offer insights into market trends, empowering you to interpret
                      signals and make trades based on your analysis and strategy.`}</Typography>
                  </ShowMoreText>
                ) : (
                  <Typography variant="body2" textAlign={'justify'}>
                    {`Choose this if you prefer making trading decisions manually
                  with help from technical analysis. TradingView indicators
                  offer insights into market trends, empowering you to interpret
                  signals and make trades based on your analysis and strategy.`}
                  </Typography>
                )}
              </li>
              <li>
                <strong>Strategy:</strong>
                {isMdDown ? (
                  <ShowMoreText
                    lines={2}
                    more={'Read More'}
                    less="Less"
                    className="show-more"
                    anchorClass="anchor-show-more"
                    expanded={false}
                    truncatedEndingComponent={'...'}
                  >
                    <Typography variant="body2" textAlign={'justify'}>
                      {`Select this if you want your trades to run automatically based
                  on set conditions. It's hands-free trading that takes
                  advantage of market opportunities according to your chosen
                  strategy, coded in Pine Script.`}
                    </Typography>
                  </ShowMoreText>
                ) : (
                  <Typography variant="body2" textAlign={'justify'}>
                    {`Select this if you want your trades to run automatically based
                  on set conditions. It's hands-free trading that takes
                  advantage of market opportunities according to your chosen
                  strategy, coded in Pine Script.`}
                  </Typography>
                )}
              </li>
            </ul>
          </Typography>
        </Typography>
      </Alert>
      <Box
        mx={'auto'}
        mt={{ sm: 0, md: 8 }}
        display={'flex'}
        flex={1}
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <Card
          sx={{
            m: 1,
            borderWidth: '1px',
            borderStyle: type === 'indicator' ? 'solid' : '',
            borderRadius: '1em',
            borderColor: type === 'indicator' ? 'common.black' : '',
            height: '200px',
            width: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            // boxShadow:
            //   type === 'indicator'
            //     ? '0px 8px 12px rgba(0, 0, 0, 0.2)'
            //     : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);',
            backgroundColor: type === 'indicator' ? 'common.white' : '', // Adjust background color as needed
            transition: 'box-shadow 0.3s ease-in-out', // Add smooth transition
          }}
          onClick={() => handleClick('indicator')}
        >
          <img
            style={{ filter: isDarkTheme ? 'invert(1)' : 'none' }}
            src={IndicatorLogo}
            height={'50%'}
            width={'50%'}
          />
          <Typography>Indicator</Typography>
        </Card>

        <Card
          sx={{
            m: 1,
            borderWidth: '1px',
            borderStyle: type === 'strategy' ? 'solid' : '',
            borderRadius: '1em',
            borderColor: type === 'strategy' ? 'common.black' : '',
            height: '200px',
            width: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // boxShadow:
            //   type === 'strategy'
            //     ? '0px 8px 12px rgba(0, 0, 0, 0.2)'
            //     : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);',
            cursor: 'pointer',
          }}
          onClick={() => handleClick('strategy')}
        >
          <img src={StrategyLogo} height={'50%'} width={'50%'} />
          <Typography>Strategy</Typography>
        </Card>
      </Box>
    </Box>
  );
};
