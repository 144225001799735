import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { LayoutProps } from 'types/ComponentTypes';
import SliceErrorAlert from 'app/design/uiComponents/SliceErrorAlert';
import { ToastProvider } from '../Context/ToastContext';
import Toast from '../Context/Toast';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBack from '../Common/ErrorFallBack';
import { useQueryErrorResetBoundary } from 'react-query';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useTheme } from '@mui/material/styles';
import { themeContext } from '../Context/ToggleThemeContext';

export default function SigninLayout({ children }: LayoutProps) {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const location = useLocation();
  const { reset } = useQueryErrorResetBoundary();
  const { data: siteInfo } = useGetSiteInfo();
  const { isDarkTheme } = useContext(themeContext);
  // Check internet connection is enabled or not
  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      setIsOnline(true);
      return;
    }
    setIsOnline(false);
  };

  // Bind events for check internet connection is enabled or not
  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);
  const theme = useTheme();
  return (
    <ToastProvider>
      <Toast />
      <Box
        maxWidth="xl"
        sx={{ backgroundColor: theme.palette.common.white, margin: 'auto' }}
      >
        <Grid
          maxWidth="30rem"
          container
          sx={{
            px: 3,
            py: 4,
            minHeight: '100vh',
            margin: 'auto',
            textAlign: { md: 'center' },
          }}
        >
          <Grid item xs={12} sx={{ display: 'flex', flexFlow: 'column' }}>
            <Box sx={{ textAlign: 'center' }}>
              {siteInfo && siteInfo.logo && (
                <RouterLink to="/">
                  <img
                    src={
                      isDarkTheme ? siteInfo.speedbot_dark_logo : siteInfo.logo
                    }
                  />
                </RouterLink>
              )}
            </Box>

            <Box alignSelf="center" sx={{ flex: 1 }}>
              <ErrorBoundary
                key={location.pathname}
                FallbackComponent={ErrorFallBack}
                onReset={reset}
              >
                {children}
              </ErrorBoundary>
              {!isOnline && (
                <SliceErrorAlert message="Network unreachable. Check your connection." />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ToastProvider>
  );
}
