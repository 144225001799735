import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
// import _ from 'lodash';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  FieldParam,
  DropdownFieldValue,
  DropdownFieldParam,
  FieldParamValue,
  ValidateState,
  ControlsType,
  OptionLegParam,
} from '../types';
import GlobalStyles from '@mui/material/GlobalStyles';
import { v4 } from 'uuid';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { generateValuesBeforeSend } from '../Utils/utils';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

const checkColor = [
  { value: 'call', class: 'leg-call' },
  { value: 'put', class: 'leg-put' },
  { value: 'long', class: 'leg-buy' },
  { value: 'short', class: 'leg-sell' },
];

export const DropdownFieldRenderer = (props: Props) => {
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const { param, onChange, sendValidation, isReadOnly, optionLeg } = props;
  const selected = props.selected as Record<string, string>;
  const { name, controls, parentKey, myStyle, addClass, width } =
    param as DropdownFieldParam;

  const validate = useCallback(
    (formData: Record<string, string>) => {
      let validStatus = {
        status: false,
        error: '',
      };
      Object.keys(formData).forEach((item, index) => {
        if (controls[index] && controls[index].isRequired && !formData[item]) {
          validStatus = {
            status: true,
            error: 'Please enter valid data',
          };
        }
      });
      return validStatus;
    },
    [controls],
  );
  const { isDarkTheme } = useContext(themeContext);
  useEffect(() => {
    const validStatus = validate(selected);
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);
  }, [validate, parentKey, sendValidation, selected]);
  const globaltheme = useTheme();

  const theme = useMemo(() => {
    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: globaltheme.palette.text.disabled, // Border color for disabled state
              },
              height: '56px',
              width: width ? `${width}px` : '100%',
              fontSize: '16px',
            },
            notchedOutline: {
              borderColor: globaltheme.palette.text.secondary,
            },
            input: {
              color: globaltheme.palette.text.primary,
              '&.Mui-disabled': {
                color: globaltheme.palette.text.disabled,
                ' -webkit-text-fill-color': globaltheme.palette.text.disabled,
              },
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              display: `${isReadOnly ? 'none' : 'block'}`,
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: globaltheme.palette.text.secondary,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: '16px',
              top: '7px',
              color: globaltheme.palette.text.secondary,
            },
            shrink: {
              top: '0px',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: globaltheme.palette.common.white,
              color: globaltheme.palette.common.black,
            },
          },
        },
      },
    });
  }, [width, isReadOnly, globaltheme]);

  const onChangeFieldType = (
    event: SelectChangeEvent,
    control: ControlsType,
  ) => {
    selected[control.key] = event.target.value;
    const validStatus = validate(selected);
    sendValidation(control.key, !validStatus.status);
    if (parentKey === 'parent_contract_selection_type') {
      generateValuesBeforeSend(param, selected, optionLeg as OptionLegParam);
    }
    onChange(parentKey, selected);
  };
  return (
    <Grid className={addClass} item style={{ marginBottom: '24px' }}>
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: myStyle?.small
            ? myStyle.small
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('md')]: myStyle?.medium
            ? myStyle.medium
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('lg')]: myStyle?.large
            ? myStyle.large
            : myStyle?.root
            ? myStyle.root
            : myStyle,
        }}
      />
      <ThemeProvider theme={theme}>
        <FormControl
          variant="outlined"
          style={
            width
              ? {
                  width: `${width}px`,
                }
              : {}
          }
          className="comman-input"
        >
          {controls.map(control => {
            return (
              <div key={v4()}>
                <InputLabel
                  sx={{ color: globaltheme.palette.text.secondary }}
                  id={control.key}
                >
                  {name}
                </InputLabel>
                <Select
                  label={name}
                  onChange={event => {
                    onChangeFieldType(event, control);
                  }}
                  name={name}
                  labelId={name}
                  id={name}
                  endAdornment={
                    control.tooltip && (
                      <InputAdornment sx={{ mr: 2 }} position="end">
                        <CustomTooltip tooltipKey={control.tooltip}>
                          {({ isTooltipAvailable }) =>
                            isTooltipAvailable ? (
                              <IconButton>
                                <InfoOutlinedIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )
                          }
                        </CustomTooltip>
                      </InputAdornment>
                    )
                  }
                  value={selected[control.key]}
                  style={{
                    fontSize: '16px',
                    width: '100%',
                  }}
                  className={
                    isDarkTheme
                      ? ''
                      : checkColor.find(e => e.value === selected[control.key])
                          ?.class || ''
                  }
                  error={isValidStatus.status}
                  disabled={isReadOnly}
                >
                  {control.values?.map(
                    (value: DropdownFieldValue, i: number) => {
                      if (value.key === 'divider') {
                        return <ItemSeparator key={i} variant="1px" />;
                      }
                      return (
                        <MenuItem key={value.key} value={value.key}>
                          {value.label}
                        </MenuItem>
                      );
                    },
                  )}
                </Select>
              </div>
            );
          })}
          {/* {isValidStatus.status && (
          <FormHelperText error={isValidStatus.status}>
            {isValidStatus.error}
          </FormHelperText>
        )} */}
        </FormControl>
      </ThemeProvider>
    </Grid>
  );
};
