import { Box } from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { themeContext } from '../Context/ToggleThemeContext';

const SpeedBotLogo = () => {
  const { data: siteInfo } = useGetSiteInfo();
  const isMdUp = useGetMediaQueryUp('md');
  const { isDarkTheme } = useContext(themeContext);
  return (
    <>
      {!isMdUp && (
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          {siteInfo && siteInfo.logo && (
            <RouterLink to="/">
              <img
                src={isDarkTheme ? siteInfo.speedbot_dark_logo : siteInfo.logo}
              />
            </RouterLink>
          )}
        </Box>
      )}
    </>
  );
};

export default SpeedBotLogo;
