import { memo, useContext } from 'react';
import { Box } from '@mui/material';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { Link as RouterLink } from 'react-router-dom';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
function AppbarLogo() {
  const { data: siteInfo } = useGetSiteInfo();
  const isMdUp = useGetMediaQueryUp('md');
  const { isDarkTheme } = useContext(themeContext);
  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        zIndex: 1,
      }}
    >
      {siteInfo && (
        <FeatureFlag
          features={[FEATURES.IS_MOBILE_APP_BAR_LOGO]}
          fallback={<img src={siteInfo.small_logo} />}
        >
          {isMdUp ? (
            <RouterLink to="/">
              <img
                src={isDarkTheme ? siteInfo.speedbot_dark_logo : siteInfo.logo}
              />
            </RouterLink>
          ) : (
            <RouterLink to="/">
              <img src={siteInfo.small_logo} />
            </RouterLink>
          )}
        </FeatureFlag>
      )}
      {/* <Typography variant="h5" sx={{ pl: 1 }} fontWeight="500">
        SpeedBot
      </Typography> */}
    </Box>
  );
}

export default memo(AppbarLogo);
