import { Box } from '@mui/material';

import {
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
  CarouselImage5,
  CarouselImage6,
  CarouselImage7,
  CarouselImage8,
} from 'app/components/SvgComponents/ImportExportImage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import CarouselItem from './CarouselItem';
const ImageComp = ({ image }: { image: string }) => {
  return (
    <img
      style={{ objectFit: 'contain', height: '100%', width: '100%' }}
      src={image}
    />
  );
};
const items = [
  <CarouselItem
    title="No-code Strategy Builder"
    description="Build your trading bots that match your trading style.
Effortlessly create and deploy sophisticated trading strategies without writing a single line of code.
"
    key={1}
  >
    <ImageComp image={CarouselImage7} />
  </CarouselItem>,
  <CarouselItem
    title="Detailed Reporting"
    description="Know your numbers, master your trade.
Gain deep insights into your trading performance with comprehensive reports
"
    key={2}
  >
    <ImageComp image={CarouselImage4} />
  </CarouselItem>,
  <CarouselItem
    title="Ready-to-use Strategy Templates"
    description="Start trading like a pro, instantly with beginner-friendly templates
Start  your trading journey with pre-built templates or customize them according to your taste
"
    key={3}
  >
    <ImageComp image={CarouselImage8} />
  </CarouselItem>,
  <CarouselItem
    title="24/7 market monitoring"
    description="Never miss a trading opportunity, day or night.
Stay ahead of the market with round-the-clock monitoring and alerts.
"
    key={4}
  >
    <ImageComp image={CarouselImage1} />
  </CarouselItem>,
  <CarouselItem
    title="Backtest Your Strategy"
    description="Learn from the past, trade the future
Test your strategies against historical market data to assess their potential.
"
    key={5}
  >
    <ImageComp image={CarouselImage3} />
  </CarouselItem>,
  <CarouselItem
    title="Forward Testing"
    description="Test your strategies in the live market
Validate your strategies in real-time market conditions to gauge their effectiveness.
"
    key={6}
  >
    <ImageComp image={CarouselImage5} />
  </CarouselItem>,
  <CarouselItem
    title="Advanced Risk Management"
    description="Trade with confidence, not fear
Safeguard your capital with robust risk management features.
"
    key={7}
  >
    <ImageComp image={CarouselImage2} />
  </CarouselItem>,
  <CarouselItem
    title="lock profit"
    description="Protect your profits, lower risk
Secure your gains and limit your losses with automated profit locking feature.
"
    key={8}
  >
    <ImageComp image={CarouselImage6} />
  </CarouselItem>,
];

const CarouselIntro = ({
  onAuthPageChange,
  handleClose,
}: {
  onAuthPageChange: (view: string) => void;
  handleClose: () => void;
}) => {
  const { data: stiteInfo } = useGetSiteInfo();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      className="carousel-container"
      justifyContent={'space-between'}
    >
      <Box
        p={2}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'start'}
      >
        {stiteInfo && stiteInfo.logo && (
          <RouterLink to="/">
            <img src={stiteInfo.logo} />
          </RouterLink>
        )}

        <MuiButton
          size="small"
          onClick={handleClose}
          startIcon={<CloseIcon />}
          sx={{ px: '0 !important' }}
        >
          <FormattedMessage id="buttons.close" />
        </MuiButton>
      </Box>
      <AliceCarousel
        mouseTracking
        items={items}
        autoPlay
        autoPlayInterval={2000}
        infinite
        disableDotsControls={true}
        disableButtonsControls={true}
      />
      <Box sx={{ display: 'flex', rowGap: 1, p: 2, flexDirection: 'column' }}>
        <MuiButton
          sx={{ borderRadius: '2rem' }}
          size="large"
          variant="outlined"
          color="primary"
          fullWidth={true}
          onClick={() => {
            onAuthPageChange('Sign In');
          }}
        >
          Login
        </MuiButton>
        <MuiButton
          sx={{ borderRadius: '2rem' }}
          size="large"
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => {
            onAuthPageChange('Sign Up');
          }}
        >
          Signup
        </MuiButton>
      </Box>
    </Box>
  );
};

export default CarouselIntro;
