import { memo, useContext } from 'react';
import { Box } from '@mui/material';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { Link as RouterLink } from 'react-router-dom';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
const SidebarLogo = ({ open }: { open: boolean }) => {
  const { data: siteInfo } = useGetSiteInfo();
  const { isDarkTheme } = useContext(themeContext);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // zIndex: 1,
      }}
    >
      {siteInfo && (
        <FeatureFlag
          features={[FEATURES.IS_MOBILE_APP_BAR_LOGO]}
          fallback={<img src={siteInfo.small_logo} />}
        >
          {open ? (
            <RouterLink to="/">
              <img
                src={isDarkTheme ? siteInfo.speedbot_dark_logo : siteInfo.logo}
                style={{
                  // filter: `invert(${isDarkTheme ? 1 : 0})`,
                  // mixBlendMode: isDarkTheme ? 'luminosity' : 'normal',
                  objectFit: 'contain',
                }}
              />
            </RouterLink>
          ) : (
            <RouterLink to="/">
              <img src={siteInfo.small_logo} />
            </RouterLink>
          )}
        </FeatureFlag>
      )}
    </Box>
  );
};

export default memo(SidebarLogo);
