import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import SigninPageContentLayout from 'app/design/layouts/SigninPageContentLayout';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import * as Yup from 'yup';
import 'yup-phone';
import { Formik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiButton from 'app/design/uiComponents/MuiButton';
import {
  getQueryParam,
  parseFormattedPhoneNumber,
} from 'utils/GenericFunctions';
import { DEFAULT_COUNTRY_CODE } from 'constants/common';
import DoneIcon from '@mui/icons-material/Done';
// import { FEATURES } from 'types/Feature';
// import _ from 'lodash';
// service
import useMobileSignup from 'services/Auth/useMobileSignup';
import { logFirebaseEventEntry } from 'utils/firebaseAnalyticsUtils';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import useReferralValidate from 'services/Auth/useReferralValidate';
// import { FeatureFlag } from 'app/components/Common/FeatureFlag';
// import { checkFeatureStatus } from 'app/components/Common/FeatureFlag/featureChecker';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { themeContext } from '../Context/ToggleThemeContext';
interface ObjProps {
  ccode: string;
  pnum: string;
  password: string;
  email: string;
}
export function SignupStartPage({
  onAuthPageChange,
  handleUseParams,
  data,
}: {
  onAuthPageChange: (view: string) => void;
  handleUseParams: (obj: ObjProps) => void;
  data?: string;
}) {
  const history = useHistory();
  const { data: siteInfo } = useGetSiteInfo();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  setTimeout(() => setShowError(true), 2000);
  const [startPageReferralCode] = useState<string>(getQueryParam('referral'));
  useEffect(() => {
    // Log entry for init app
    logFirebaseEventEntry('APP_SIGNUP_START_INIT');
  }, []);
  const {
    referralCode,
    setReferralCode,
    referralValidate,
    // isError,
    // isLoading,
    debouncedRefetch,
  } = useReferralValidate(startPageReferralCode);

  // const showReferral = checkFeatureStatus('REACT_APP_IS_REFERRAL_CODE');
  const isShowReferral = useIsFeatureFlag([FEATURES.IS_REFERRAL_CODE]);
  // handle toggle show/hide password texts
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const isMdUp = useGetMediaQueryUp('md');
  // handle toggle show/hide confirm password texts
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const signupValidationSchema = Yup.object().shape({
    username: Yup.string().required('Name is required.'),
    country_code: Yup.string()
      .min(1, 'Country code must be at least one digit.')
      .max(3, 'Country code must be less than three digit.')
      .required('Country code is required.'),
    phone: Yup.string().required('Phone number is required.'),
    mobile: Yup.string()
      .phone(undefined, undefined, 'Please enter a valid phone number.')
      .required('Phone number is required.'),
    password: Yup.string()
      .required('Password is required')
      .min(5, 'Password must be at least 5 characters'),
    confirmPass: Yup.string()
      .required('Confirm password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Password and Confirm password must match.',
      ),
    email: Yup.string()
      .required('Email is required')
      .email('Please enter valid email address'),
    // email: Yup.string().when('country_code', {
    //   is: (value: string) => {
    //     return value !== DEFAULT_COUNTRY_CODE;
    //   },
    //   then: (schema: Yup.StringSchema) =>
    //     schema
    //       .email('Please enter valid email address')
    //       .required('Email address is mandatory for non indian phone number.'),
    //   otherwise: (schema: Yup.StringSchema) => schema.optional(),
    // }),
  });

  const mutation = useMobileSignup();
  // const queryParam = getQueryParam('ref');
  const handleCancelClick = () => {
    onAuthPageChange('Sign In');
  };
  const handleNextClick = () => {
    onAuthPageChange('SignUp Verify');
  };
  const { isDarkTheme } = useContext(themeContext);
  return (
    <React.Fragment>
      <Helmet>
        <title>Signup Verify Phone Page</title>
        <meta name="description" content="Signup verify phone page" />
      </Helmet>
      <CircularLoader open={mutation.isLoading} />
      <SigninPageContentLayout>
        {/* Must have max two child element */}
        <Box sx={{ maxWidth: '20rem', marginX: { md: 'auto', sm: 'auto' } }}>
          {!isMdUp && (
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              {siteInfo && siteInfo.logo && (
                <RouterLink to="/">
                  <img
                    src={
                      isDarkTheme ? siteInfo.speedbot_dark_logo : siteInfo.logo
                    }
                  />
                </RouterLink>
              )}
            </Box>
          )}
          <Typography
            variant="h6"
            color="primary.dark"
            sx={{ mt: 4, textAlign: 'center' }}
          >
            <FormattedMessage id="create_your_account" />
          </Typography>
          <Formik
            initialValues={{
              username: '',
              country_code:
                siteInfo && siteInfo.default_country_code
                  ? siteInfo.default_country_code
                  : DEFAULT_COUNTRY_CODE,
              phone: '',
              mobile:
                siteInfo && siteInfo.default_country_code
                  ? siteInfo.default_country_code
                  : DEFAULT_COUNTRY_CODE,
              email: '',
              password: '',
              confirmPass: '',
              domain: location.host,
            }}
            validationSchema={signupValidationSchema}
            onSubmit={values => {
              handleUseParams({
                ccode: values.country_code,
                pnum: values.phone,
                email: values.email,
                password: values.password,
              });
              mutation.mutate(
                { ...values, referral: referralCode },
                {
                  onSuccess: res => {
                    if (res.email) {
                      // Log entry for init app
                      logFirebaseEventEntry('APP_SIGNUP_SUCCESS', res);
                      void handleNextClick();
                      // const refQueryParam = queryParam
                      //   ? `?ref=${queryParam}`
                      //   : '';
                      history.push({
                        // pathname: `/signup/verifycode/${values.country_code}/${values.phone}`,

                        state: {
                          password: values.password,
                          email: values.email,
                        },
                        // search: refQueryParam,
                      });
                    }
                  },
                },
              );
              // if (mutation.data && mutation.data.error) {
              //   return;
              // } else {
              //   handleNextClick();
              // }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <Box
                component="form"
                noValidate
                id="mobile_signup_form"
                onSubmit={handleSubmit}
                sx={{ mt: 2 }}
              >
                {mutation.data && mutation.data.error && (
                  <Alert sx={{ my: 2 }} severity="error">
                    {mutation.data.error.includes(
                      'OTP feature is not available for you!',
                    )
                      ? 'Thanks for Signing up – the Admin will review and get back to you at the earliest.'
                      : mutation.data.error}
                  </Alert>
                )}
                <TextField
                  fullWidth
                  label="Name"
                  name="username"
                  error={Boolean(touched.username && errors.username)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  type="text"
                />
                {touched.username && errors.username && (
                  <FormHelperText error>{errors.username}</FormHelperText>
                )}
                <MuiPhoneNumber
                  sx={{
                    mt: 1,
                    '& .MuiPhoneNumber-flagButton': { width: '30px' },
                  }}
                  fullWidth
                  label="Phone"
                  name="phone"
                  defaultCountry={
                    siteInfo && siteInfo.default_country
                      ? siteInfo.default_country
                      : 'in'
                  }
                  disableAreaCodes={true}
                  disableCountryCode={false}
                  autoFormat={true}
                  error={Boolean(
                    touched.phone && (errors.phone || errors.mobile),
                  )}
                  onBlur={handleBlur}
                  onChange={(
                    formattedNumber:
                      | string
                      | React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                    country?: {
                      name: string;
                      dialCode: string;
                      countryCode: string;
                    },
                  ) => {
                    if (country) {
                      const phoneData = parseFormattedPhoneNumber(
                        formattedNumber as string,
                        country.dialCode,
                      );
                      setFieldValue('phone', phoneData.phone, false);
                      setFieldValue(
                        'country_code',
                        phoneData.countryCode,
                        false,
                      );
                      setFieldValue(
                        'mobile',
                        '+' + phoneData.countryCode + phoneData.phone,
                        false,
                      );
                    }
                  }}
                  variant="outlined"
                />
                {touched.phone && errors.phone && (
                  <FormHelperText error>{errors.phone}</FormHelperText>
                )}
                {touched.phone && !errors.phone && errors.mobile && (
                  <FormHelperText error>{errors.mobile}</FormHelperText>
                )}
                <TextField
                  sx={{ mt: 4 }}
                  fullWidth
                  // label={
                  //   values.country_code === DEFAULT_COUNTRY_CODE
                  //     ? 'Email (Optional)'
                  //     : 'Email'
                  // }
                  label="Email"
                  type="email"
                  onChange={handleChange}
                  name="email"
                  error={Boolean(touched.email && errors.email)}
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="standard-weight-helper-text-email">
                    {errors.email}
                  </FormHelperText>
                )}
                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  <InputLabel htmlFor="outlined-adornment-password-login">
                    <FormattedMessage id="password_title" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-login"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                      sx={{ mx: 0 }}
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  <InputLabel htmlFor="outlined-adornment-password-login">
                    <FormattedMessage id="confirm_password_title" />
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password-login"
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPass"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                    inputProps={{}}
                  />
                  {touched.confirmPass && errors.confirmPass && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-confirm-password-login"
                      sx={{ mx: 0 }}
                    >
                      {errors.confirmPass}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            )}
          </Formik>
          {/* <FeatureFlag features={[FEATURES.IS_REFERRAL_CODE]} fallback={<></>}> */}
          {isShowReferral && (
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
              <TextField
                sx={{ mt: 4 }}
                fullWidth
                label="Referral Code (optional)"
                name="referral"
                value={referralCode || data}
                onBlur={() => {
                  if (
                    referralCode.trim() !== '' &&
                    referralCode.trim().length > 3
                  ) {
                    debouncedRefetch();
                  }
                }}
                // onChange={async event => {
                //   const newReferralCode = event.target.value;
                //   setReferralCode(newReferralCode);
                //   await debouncedRefetch(newReferralCode); // This will ensure the fetch is debounced
                // }}
                onChange={e => setReferralCode(e.target.value)}
                // error={Boolean(touched.referral && errors.referral)}
                variant="outlined"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {referralCode.trim() !== '' &&
                        (referralValidate && referralValidate.success ? (
                          <DoneIcon color="success" fontSize="medium" />
                        ) : // <CloseOutlined
                        //   style={{ color: 'red' }}
                        //   fontSize="medium"
                        // />
                        null)}
                    </InputAdornment>
                  ),
                }}
              />
              {showError &&
                referralCode.trim() !== '' &&
                (referralValidate && referralValidate.success ? (
                  // <DoneIcon color="success" fontSize="medium" />
                  ''
                ) : (
                  <Typography color={'error'}>
                    Oops! It seems your referral code isn<span>&apos;</span>t
                    valid, Double-check and retry!
                  </Typography>
                ))}
              {/* {referralCode.trim() !== '' && isError ? (
              <FormHelperText error>An error occurred.</FormHelperText>
            ) : null} */}
            </FormControl>
          )}
          {/* </FeatureFlag> */}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            mb: 2,
            alignItems: 'center',
          }}
          textAlign="center"
        >
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            form="mobile_signup_form"
            fullWidth={true}
            sx={{ mt: 2 }}
            disabled={
              mutation.isLoading ||
              (referralCode.trim() !== '' ? !referralValidate?.success : false)
            }
          >
            <FormattedMessage id="next" />
          </MuiButton>
          <MuiButton
            className="loginflowBtn"
            size="large"
            variant="contained"
            color="secondary"
            fullWidth={true}
            // component={RouterLink}
            // to={
            //   // process.env.IS_GOOGLE_USER_ENABLE
            //   //   ? `/signup?ref=${queryParam}`
            //   //   : `/signin?ref=${queryParam}`
            //   undefined
            // }
            onClick={() => handleCancelClick()}
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="cancel" />
          </MuiButton>
        </Box>
      </SigninPageContentLayout>
    </React.Fragment>
  );
}

export default SignupStartPage;
